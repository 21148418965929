@media screen and (max-width: 768px) {
  input {
    border-radius: 0 !important;
  }
}

.nuovo-contratto {
  margin: 32px;
}
.page-header {
  margin: 36px 0 18px;
  padding-bottom: 8px;
  font-weight: 600;
  font-size: 24px;
  color: #39fbc7;
}
.nuovo-contratto-btn {
  margin-top: 32px !important;
}

.contratto-esistente {
  /*margin-top: 12px;*/
}
th {
  padding: 10px;
}
.descrizione{
  width: 35%
}
.nomeProdotto{
  width:15%
}
.table-body {
  gap: -1px;
}
tr {
  border: 1px solid black;
}
.contratto-button {
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
}

.checkbox-button {
  margin-right: 8px;
}
.checkbox-label {
  font-size: 16px;
  font-weight: normal;
}
.label-element {
  font-size: 16px;
  font-weight: normal;
}
th {
  cursor: default;
}
