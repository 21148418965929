.login{
    display:flex;
    height:100vh;
    width:32vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
@media screen and (max-width:1400px){
    .img{
        width:64vw!important;
    }
}
.img{
    width:32vw;
    margin-bottom: 16px;
}


.login-button{
    display:flex;
    justify-content: center;
    margin-top: 16px;
}
label{
    margin-bottom: 5px;
    margin-top: 5px;
    font-weight: 600;
}
.error-login{
    color: red;
    font-size: 16px;
    margin-top: 8px;
}
