.dispatcher {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100vh;
}
.button-container {
  display: flex;
  gap: 64px;
  margin-top: 24px;
  /* flex-wrap: wrap; */
}

.btn-dispatcher {
  height: 6vh;
  width: 16vw;
  background-color: #39fbc7 !important;
  border: none;
  padding: 12px 24px !important;
}

@media (max-width: 768px) {
  .button-container {
    flex-direction: column;
    gap: 32px;
  }
  .btn-dispatcher {
    width: 100%;
  }
}

.img {
  width: 32vw;
  margin-bottom: 64px;
}
.dispatcher-button {
  position: absolute;
  right: 4.6vw;
  top: 4.6vh;
}
