.lista-contratti {
    margin: 32px;
    font-size: 22px;
}
.header-container {
    display: flex;
    justify-content: space-between;
    margin: 36px 0 18px;
}
.page-header {
    font-size: 36px;
    margin: 0;
}
.search-box {
    /* width: 16vw; */
    display: flex;
    align-items: center;
    gap: 16px
}
.header-table {
    border-bottom: 1px solid lightgrey;
}
/*thead > tr{*/
/*    border-bottom: 1px solid black;*/
/*}*/
tbody {
    font-size: 18px;
}
tbody > tr {
    border-bottom: 1px solid lightgrey;
    cursor: pointer;
}
thead > tr > th {
    padding: 10px 16px;
    cursor: pointer;
}
tbody > tr > th {
    padding: 24px 16px;
    font-weight: normal;
    cursor: pointer;
}

table {
    width: 100%;
    /*border:1px solid black;*/
}

tbody > tr > th > a {
    z-index: 1000;
}

@media (max-width: 768px) {
    .header-container {
        flex-direction: column;
    }
    .form-group {
        width: 100%;
    }
    .form-control {
        width: 100%;
    }
}
